import { Controller } from "~/controllers";

export default class extends Controller {
  static values = {
    hiddenCommentCount: Number,
  };
  static targets = ["collapsedComments", "toggleIcon", "toggleText"];

  toggleComments() {
    const commentsNowHidden = this.collapsedCommentsTarget.classList.toggle("hidden");
    this.toggleTextTarget.textContent = commentsNowHidden
      ? `Show ${this.hiddenCommentCountValue} more comments`
      : "Hide comments";
    this.toggleIconTarget.classList.toggle("rotate-180");
  }
}
