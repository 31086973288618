import { Controller } from "~/controllers";

const PAYMENT_TYPE = {
  CREDIT_BALANCE: "credit_balance",
  CREDIT_CARD: "credit_card",
  CASH: "cash",
  CHECK: "check",
};

export default class extends Controller {
  static targets = [
    "amount",
    "submit",
    "error",
    "allocation",
    "patientCreditBalanceCheckbox",
    "overpaymentCheckbox",
    "overpaymentCheckboxLabel",
    "paymentType",
    "maximum",
    "allocationError",
    "checkNumber",
    "checkNumberContainer",
  ];

  connect() {
    this.validateAmount();
    this.submitted = false;
    this.valid = false;

    // Initialize with credit balance if available
    const creditBalance = parseFloat(this.amountTarget.dataset.patientCreditBalance) || 0;
    if (creditBalance > 0) {
      this.paymentTypeTarget.value = PAYMENT_TYPE.CREDIT_BALANCE;
      this.typeChange();
    }
  }

  submitForm(event) {
    event.preventDefault();
    this.submitted = true;
    this.validateAmount();
    if (this.valid) {
      // If form is valid, proceed with submission
      this.element.submit();
    }
  }

  togglePatientCreditBalanceUsage() {
    const useCreditBalance = this.patientCreditBalanceCheckboxTarget.checked;
    const creditBalance = parseFloat(this.amountTarget.dataset.patientCreditBalance) || 0;
    const amountRemaining = parseFloat(this.amountTarget.dataset.remaining);
    if (useCreditBalance) {
      const creditBalanceAmountToUse = Math.min(creditBalance, amountRemaining);
      this.amountTarget.value = creditBalanceAmountToUse.toFixed(2);
      this.paymentTypeTarget.value = PAYMENT_TYPE.CREDIT_BALANCE;
      this.maximumTarget.textContent = "Maximum amount: " + creditBalanceAmountToUse.toFixed(2);
      this.overpaymentCheckboxTarget.checked = false;
      this.hideOverpaymentCheckbox();
      this.checkNumberContainerTarget.classList.add("hidden");
      this.checkNumberTarget.value = "";
    } else {
      this.paymentTypeTarget.value = PAYMENT_TYPE.CASH;
      this.maximumTarget.textContent = "Outstanding amount: " + amountRemaining.toFixed(2);
      this.checkNumberContainerTarget.classList.add("hidden");
      this.checkNumberTarget.value = "";
    }
    this.validateAmount();
  }

  typeChange() {
    if (this.paymentTypeTarget.value === PAYMENT_TYPE.CREDIT_BALANCE) {
      this.patientCreditBalanceCheckboxTarget.checked = true;
      this.checkNumberContainerTarget.classList.add("hidden");
      this.checkNumberTarget.value = "";
      this.togglePatientCreditBalanceUsage();
    } else if (this.paymentTypeTarget.value === PAYMENT_TYPE.CHECK) {
      this.checkNumberContainerTarget.classList.remove("hidden");
    } else {
      this.patientCreditBalanceCheckboxTarget.checked = false;
      this.checkNumberContainerTarget.classList.add("hidden");
      this.checkNumberTarget.value = "";
    }
  }

  createOverpaymentSelected() {
    if (this.overpaymentCheckboxTarget.checked) {
      this.valid = true;
    } else this.valid = false;
  }

  showOverpaymentCheckbox() {
    this.overpaymentCheckboxTarget.classList.remove("hidden");
    this.overpaymentCheckboxLabelTarget.classList.remove("hidden");
  }

  hideOverpaymentCheckbox() {
    this.overpaymentCheckboxTarget.classList.add("hidden");
    this.overpaymentCheckboxLabelTarget.classList.add("hidden");
  }

  displayErrorText(textContent) {
    this.errorTarget.textContent = textContent;
    this.errorTarget.hidden = false;
  }

  validateAmount() {
    const paymentAmount = parseFloat(this.amountTarget.value) || 0;
    const remainingBalance = parseFloat(this.amountTarget.dataset.remaining) || 0;
    const creditBalance = parseFloat(this.amountTarget.dataset.patientCreditBalance) || 0;
    const useCreditBalance = this.patientCreditBalanceCheckboxTarget.checked;

    let totalAllocated = 0;
    let displayAllocationError = false;
    let displayNegativeAllocationError = false;
    this.allocationTargets.forEach((target) => {
      let allocationAmount = parseFloat(target.value) || 0;
      totalAllocated += allocationAmount;
      let remainingTreatmentBalance = parseFloat(target.dataset.treatmentAmount);

      // Math.max is to cover the case where the remaining treatment balance for patient is negative
      // this can happen if the insurance is estimated to pay for the treatment and patient doesn't have anything left to pay
      // Note: should we allow for patient to pay even if insurance is estimated to pay for the treatment?
      if (allocationAmount > Math.max(0, remainingTreatmentBalance)) {
        displayAllocationError = true;
      }

      if (allocationAmount < 0) {
        displayNegativeAllocationError = true;
      }
    });

    if (displayAllocationError) {
      this.valid = false;
      //  Display overpayment dialogue
      // Set param to allow overpayment.
      if (this.submitted) {
        this.allocationErrorTarget.textContent =
          "Allocation amount exceeds remaining treatment balance.";
        this.allocationErrorTarget.hidden = false;
      }
      return;
    } else {
      this.allocationErrorTarget.textContent = "";
      this.allocationErrorTarget.hidden = true;
    }

    if (displayNegativeAllocationError) {
      this.valid = false;
      //  Display overpayment dialogue
      // Set param to allow overpayment.
      if (this.submitted) {
        this.allocationErrorTarget.textContent = "Cannot submit a form with negative allocations.";
        this.allocationErrorTarget.hidden = false;
      }
      return;
    } else {
      this.allocationErrorTarget.textContent = "";
      this.allocationErrorTarget.hidden = true;
    }

    if (paymentAmount === 0) {
      this.valid = false;
      if (this.submitted) {
        this.displayErrorText("Please enter a payment amount.");
        this.hideOverpaymentCheckbox();
      }
      return;
    }

    if (paymentAmount < 0) {
      this.valid = false;
      if (this.submitted) {
        this.displayErrorText("Unable to sumbit a negative payment.");
        this.hideOverpaymentCheckbox();
      }
      return;
    }

    if (useCreditBalance && paymentAmount > creditBalance) {
      this.valid = false;
      if (this.submitted) {
        this.displayErrorText("Payment amount exceeds the patient's credit balance.");
      }
      return;
    }

    if (paymentAmount > remainingBalance) {
      if (this.overpaymentCheckboxTarget.checked && !useCreditBalance) {
        this.valid = true;
        return;
      } else if (useCreditBalance) {
        this.valid = false;
        if (this.submitted) {
          this.displayErrorText("Cannot create an overpayment using patient credit balance.");
        }
        this.hideOverpaymentCheckbox();
        this.overpaymentCheckboxTarget.checked = false;
        return;
      } else {
        this.valid = false;
        this.showOverpaymentCheckbox();
        this.errorTarget.textContent = "";
        return;
      }
    }

    if (totalAllocated > paymentAmount) {
      this.valid = false;
      if (this.submitted) {
        this.displayErrorText("Total allocated amounts exceed the payment amount.");
      }
      return;
    }
    this.valid = true;
    this.errorTarget.hidden = true;
    this.hideOverpaymentCheckbox();
  }
}
