import { Controller } from "~/controllers";

export default class extends Controller {
  connect() {
    this.element.addEventListener("change", this.handleChange);
  }

  disconnect() {
    this.element.removeEventListener("change", this.handleChange);
  }

  handleChange = (event) => {
    const key = event.target.getAttribute("data-leader");
    if (!key) {
      return;
    }
    this.element.querySelectorAll("[data-follower]").forEach((follower) => {
      if (follower.getAttribute("data-follower") === key) {
        follower.setAttribute("data-checked", "");
      } else {
        follower.removeAttribute("data-checked");
      }
    });
  };
}
