import {
  createEffect,
  createMemo,
  createSignal,
  onCleanup,
  Match,
  Show,
  Switch,
  createUniqueId,
} from "solid-js";
import { offset, shift } from "@floating-ui/dom";
import { createMutation, createQuery } from "@tanstack/solid-query";
import type HTML2Canvas from "html2canvas";

import { fetchJSON, type PatientId } from "~/api";
import type { TreatmentVisit } from "~/api/treatment_visits";
import { CheckCircle, XCircle, ExclamationTriangle, XMark } from "~/icon";
import { formatLongDate } from "~/util/format";
import clsx from "~/util/html/style";
import { createPopoverWithClickOutside } from "~/util/html/popover";
import { SignaturePadCanvas, loadHTML2Canvas, loadSignaturePad } from "~/form/signature/pad";
import type { TreatmentPlan, TreatmentPlanId } from "~/api/treatment_plans";
import { canvasToPNGBlob } from "~/form/blob";
import { Dialog } from "~/util/html/dialog";
import { useOdontogramEditContext } from "~/charting/odontogram/context";

const PROOF_WIDTH = 700;
const POPOVER_MIDDLEWARE = [offset(8), shift({ padding: 8 })];
const DOT_DOT_MENU_ITEM_CX =
  "block w-full px-4 py-3 transition hover:bg-brand-light text-left first:rounded-t-lg last:rounded-b-lg";
const AGREEMENTS_BASE_PATH = "/treatment_agreements/";

function QRCode(props: { params: string }) {
  const [loading, setLoading] = createSignal(true);
  return (
    <img
      alt=""
      class={clsx(
        "mx-auto block aspect-square rounded-lg p-3",
        loading() && "animate-pulse bg-brand-subtle",
      )}
      onLoad={() => {
        setLoading(false);
      }}
      src={`${AGREEMENTS_BASE_PATH}/qr_code_new_link.svg?${props.params}`}
    />
  );
}

function RenamePlanModal(props: {
  treatmentPlan: { id: TreatmentPlanId; name: string };
  onClose: () => void;
}) {
  const odontogramEditContext = useOdontogramEditContext();
  const textId = createUniqueId();
  let inputRef: HTMLInputElement | undefined;
  return (
    <Dialog class="max-w-md" onClose={props.onClose}>
      <header class="mb-6 flex items-center justify-between">
        <h1 class="text-xl">Rename treatment plan</h1>
        <button type="button" onClick={() => props.onClose()}>
          <XMark class="size-4" />
        </button>
      </header>
      <form
        onSubmit={(event) => {
          event.preventDefault();
          odontogramEditContext.renameTreatmentPlan
            .rename(props.treatmentPlan.id, inputRef!.value)
            .then(() => props.onClose()); // eslint-disable-line solid/reactivity
        }}
      >
        <div>
          <label class="text-sm font-medium text-typography-secondary" for={textId}>
            Name
          </label>
          <input
            ref={inputRef}
            type="text"
            class="mt-2 flex w-full items-center justify-between rounded-md border border-gray-300 px-3 py-1.5 text-left text-sm text-gray-900 shadow-sm"
            id={textId}
            value={props.treatmentPlan.name}
          />
        </div>
        <footer class="flex items-center justify-end">
          <button
            type="submit"
            class="brand-button-bg mt-7 rounded-full px-5 py-2 text-white transition hover:bg-brand-active"
            disabled={odontogramEditContext.renameTreatmentPlan.isPending()}
          >
            Save
          </button>
        </footer>
      </form>
    </Dialog>
  );
}

export function SignatureDotDotDot(
  props:
    | { treatmentPlan: TreatmentPlan; treatmentVisit?: never }
    | { treatmentPlan?: never; treatmentVisit: TreatmentVisit },
) {
  let triggerRef: HTMLButtonElement | undefined, popoverRef: HTMLDivElement | undefined;

  const params = () => {
    const args: Record<string, string> = props.treatmentPlan
      ? { treatment_plan_id: props.treatmentPlan.id }
      : { treatment_visit_id: props.treatmentVisit.id };
    return new URLSearchParams(args).toString();
  };

  const [renamePlanModalOpen, setRenamePlanModalOpen] = createSignal<TreatmentPlan | null>(null);
  const [tooltipState, setToolTipState] = createSignal<"hidden" | "main" | "qr">("hidden");
  const visible = createMemo(() => tooltipState() !== "hidden");
  const close = () => setToolTipState("hidden");
  createEffect(() => {
    if (visible()) {
      onCleanup(
        createPopoverWithClickOutside({
          trigger: triggerRef!,
          popover: popoverRef!,
          config: { middleware: POPOVER_MIDDLEWARE, placement: "bottom-end" },
          onClickOutside: close,
        }),
      );
    }
  });

  const agreement = () => {
    const treatmentVisit = props.treatmentPlan?.treatmentVisits[0] || props.treatmentVisit;
    return treatmentVisit && "id" in treatmentVisit.agreement && treatmentVisit.agreement;
  };

  return (
    <>
      <Show when={renamePlanModalOpen()}>
        {(plan) => (
          <RenamePlanModal onClose={() => setRenamePlanModalOpen(null)} treatmentPlan={plan()} />
        )}
      </Show>
      <button
        ref={triggerRef}
        type="button"
        class="flex min-w-4 flex-col items-center justify-center gap-0.5 *:size-[3px] *:rounded *:bg-brand-secondary"
        onClick={() => {
          setToolTipState((o) => (o === "hidden" ? "main" : "hidden"));
        }}
      >
        <div />
        <div />
        <div />
      </button>
      <div
        ref={popoverRef}
        class={clsx(
          "fixed z-floating-attachment w-[min(250px,100%)] rounded-lg border border-brand-divider bg-white text-sm shadow-lg",
          !visible() && "hidden",
        )}
      >
        <Switch>
          <Match when={tooltipState() === "qr"}>
            <QRCode params={params()} />
          </Match>
          <Match when={tooltipState() === "main"}>
            <Show when={agreement()}>
              {(agreement) => (
                <a
                  target="_blank"
                  class={DOT_DOT_MENU_ITEM_CX}
                  href={`${AGREEMENTS_BASE_PATH}${agreement().id}`}
                >
                  View signature
                </a>
              )}
            </Show>
            <a href={`${AGREEMENTS_BASE_PATH}new?${params()}`} class={DOT_DOT_MENU_ITEM_CX}>
              Collect signature on this device
            </a>
            <button
              type="button"
              class={DOT_DOT_MENU_ITEM_CX}
              onClick={() => {
                setToolTipState("qr");
              }}
            >
              Collect signature with QR code
            </button>
            <Show when={props.treatmentPlan}>
              {(plan) => (
                <button
                  type="button"
                  class={DOT_DOT_MENU_ITEM_CX}
                  onClick={() => {
                    setRenamePlanModalOpen(plan());
                    setToolTipState("hidden");
                  }}
                >
                  Rename Plan
                </button>
              )}
            </Show>
          </Match>
        </Switch>
      </div>
    </>
  );
}

export function CollectSignatureButton(props: { treatmentVisit: TreatmentVisit }) {
  return (
    <div class="flex items-center gap-1 text-xs">
      <Switch>
        <Match when={props.treatmentVisit.agreement.status === "missing"}>
          <XCircle class="size-5 text-brand-danger" />
          Missing signature
        </Match>
        <Match
          when={
            props.treatmentVisit.agreement.status === "treatment_mismatch" &&
            props.treatmentVisit.agreement
          }
        >
          {(agreement) => (
            <>
              <ExclamationTriangle class="size-5 text-brand-warning" />
              Signature from {formatLongDate(agreement().createdAt)}, but it looks outdated
            </>
          )}
        </Match>
        <Match
          when={props.treatmentVisit.agreement.status === "stale" && props.treatmentVisit.agreement}
        >
          {(agreement) => (
            <>
              <XCircle class="size-5 text-brand-danger" />
              Outdated signature from {formatLongDate(agreement().createdAt)}
            </>
          )}
        </Match>
        <Match
          when={props.treatmentVisit.agreement.status === "valid" && props.treatmentVisit.agreement}
        >
          {(agreement) => (
            <>
              <CheckCircle class="size-5 text-brand-success" />
              Signature collected on {formatLongDate(agreement().createdAt)}
            </>
          )}
        </Match>
      </Switch>
      <SignatureDotDotDot treatmentVisit={props.treatmentVisit} />
    </div>
  );
}

export function SignatureCollection(props: {
  captureId: string;
  patient: { fullName: string; id: PatientId };
}) {
  const signatureModuleQuery = createQuery(() => ({
    queryKey: ["async-modules", "signature_pad"],
    queryFn: loadSignaturePad,
    reconcile: false,
    staleTime: Infinity,
  }));
  const html2CanvasModuleQuery = createQuery(() => ({
    queryKey: ["async-modules", "html2canvas"],
    queryFn: loadHTML2Canvas,
    reconcile: false,
    staleTime: Infinity,
  }));
  const [empty, setEmpty] = createSignal(true);

  const [signedDate, setSignedDate] = createSignal<Date | null>(null);
  const createAgreementMutation = createMutation(() => ({
    async mutationFn(options: { html2Canvas: typeof HTML2Canvas; element: HTMLElement }) {
      const canvas = await options.html2Canvas(options.element, {
        allowTaint: true,
        useCORS: true,
        width: PROOF_WIDTH,
        windowWidth: PROOF_WIDTH,
        scale: 1,
        ignoreElements: (element) => {
          if (element instanceof HTMLElement) {
            return Boolean(element.ariaHidden === "true" || element.dataset["captureIgnore"]);
          }
          return false;
        },
      });
      const body = new FormData();
      body.append(
        "proof_file",
        await canvasToPNGBlob(canvas),
        `${props.patient.fullName.replace(/ /g, "_")}_signature_${new Date().toISOString()}.png`,
      );
      return fetchJSON(`/treatment_agreements${window.location.search}`, {
        method: "POST",
        body,
      });
    },
  }));
  createEffect(() => {
    if (signedDate()) {
      createAgreementMutation.mutate({
        html2Canvas: html2CanvasModuleQuery.data!,
        element: document.getElementById(props.captureId)!,
      });
    }
  });
  return (
    <>
      <div class="w-[min(100%,450px)]">
        <div class="relative flex aspect-[16/7] items-center justify-center rounded-lg border border-brand-border text-lg text-typography-tertiary print:*:hidden">
          <Switch>
            <Match when={signatureModuleQuery.isPending}>
              <p class="animate-pulse">Loading signature pad. Please wait...</p>
            </Match>
            <Match when={signatureModuleQuery.data}>
              {(constructor) => (
                <SignaturePadCanvas
                  setEmpty={setEmpty}
                  empty={empty()}
                  constructor={constructor()}
                />
              )}
            </Match>
            <Match when={signatureModuleQuery.isError}>
              <p class="text-brand-danger">Something went wrong. Try again</p>
            </Match>
          </Switch>
        </div>

        <div
          class={clsx(
            "mt-1 text-right text-xs font-semibold text-typography-secondary",
            !signedDate() && "opacity-0",
          )}
          aria-hidden={!signedDate()}
        >
          {`Signature dated ${signedDate()?.toLocaleString()}`}
        </div>
      </div>

      <div
        class="relative -mb-6 mt-8 flex items-baseline overflow-hidden border-t-2 border-brand-divider py-5 print:hidden"
        data-capture-ignore="true"
      >
        <Show when={createAgreementMutation.isError}>
          <p class="text-brand-danger">Something went wrong. Try again</p>
        </Show>
        <div
          class={clsx(
            "absolute right-0 top-1/2 -translate-y-1/2 text-base font-semibold text-brand-success transition duration-500",
            !createAgreementMutation.isSuccess && "translate-x-full",
          )}
          aria-hidden={!createAgreementMutation.isSuccess}
        >
          {"You're all set! "}
          <Show when={!new URLSearchParams(window.location.search).get("from_qr_code")}>
            {"Go "}
            <a class="underline" href={`/patients/${props.patient.id}/odontogram`}>
              back to charting
            </a>
          </Show>
        </div>
        <button
          type="button"
          class={clsx(
            "brand-button-bg z-base ml-auto cursor-pointer rounded-full px-5 py-2 text-white transition duration-500 hover:bg-brand-active disabled:opacity-70",
            createAgreementMutation.isSuccess && "translate-x-full",
          )}
          aria-hidden={createAgreementMutation.isSuccess}
          tabindex={createAgreementMutation.isSuccess ? "-1" : undefined}
          disabled={empty() || !html2CanvasModuleQuery.data || createAgreementMutation.isPending}
          onClick={() => {
            setSignedDate(new Date());
          }}
        >
          Finish
        </button>
      </div>
    </>
  );
}
