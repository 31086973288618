import { onMount, onCleanup } from "solid-js";
import type SignaturePad from "signature_pad";

import clsx from "~/util/html/style";

const DEVICE_RATIO = Math.max(window.devicePixelRatio || 1, 1);
const PING_CX = "pointer-events-none absolute -left-2 -top-2 size-4 rounded-full bg-brand-primary";

export function SignaturePadCanvas(props: {
  empty: boolean;
  setEmpty: (value: boolean) => void;
  constructor: typeof SignaturePad;
}) {
  let canvasRef: HTMLCanvasElement | undefined;
  onMount(() => {
    function resize() {
      const canvas = canvasRef!;
      canvas.width = canvas.offsetWidth * DEVICE_RATIO;
      canvas.height = canvas.offsetHeight * DEVICE_RATIO;
      canvas.getContext("2d")!.scale(DEVICE_RATIO, DEVICE_RATIO);
      pad.fromData(pad.toData()); // browers clear canvases on resize so we need to re-init
      props.setEmpty(pad.isEmpty());
    }
    const pad = new props.constructor(canvasRef!, {
      penColor: "rgb(54,110,247)",
    });
    pad.addEventListener("beginStroke", () => props.setEmpty(false), { once: true });
    resize();
    window.addEventListener("resize", resize);
    onCleanup(() => {
      pad.off();
      window.removeEventListener("resize", resize);
    });
  });
  return (
    <>
      <canvas ref={canvasRef} class="absolute inset-0 h-full w-full" />

      <div
        class={clsx(
          "pointer-events-none transition-opacity duration-1000",
          !props.empty && "opacity-0",
        )}
      >
        <div
          aria-hidden="true"
          style={{ "animation-duration": "2.5s" }}
          class={clsx(PING_CX, "animate-ping")}
        />
        <div aria-hidden="true" class={PING_CX} />
        Draw signature here
      </div>
    </>
  );
}

export async function loadSignaturePad() {
  return (await import("signature_pad")).default;
}

export async function loadHTML2Canvas() {
  return (await import("html2canvas")).default;
}
