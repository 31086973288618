import {
  createMutation,
  createQuery,
  useQueryClient,
  type MutationFunction,
} from "@tanstack/solid-query";

import { fetchJSON, getOdontogramUrl, type PatientId } from "~/api";
import type { ConditionChartingGroup } from "~/api/odontogram/conditions";
import type { TreatmentPlan } from "~/api/treatment_plans";
import type { TreatmentVisit } from "~/api/treatment_visits";
import type { UniversalId } from "~/charting/odontogram/dentition";

export type ToothSlotStatus = {
  universal: UniversalId;
  unerupted?: true;
};
export type TeethSlotStatus = {
  maxillary: ToothSlotStatus[];
  mandibular: ToothSlotStatus[];
};
export type OdontogramData = {
  acceptedTreatmentVisits: TreatmentVisit[];
  archivedTreatmentPlans: TreatmentPlan[];
  completedTreatmentVisits: TreatmentVisit[];
  conditionChartingGroups: ConditionChartingGroup[];
  stagingTreatmentPlans: TreatmentPlan[];
  teethSlotStatus: TeethSlotStatus;
};

const TOP_LEVEL_CACHE_KEY = "patient-odontograms";

export function createOdontogramQuery(patientId: () => PatientId) {
  return createQuery<OdontogramData>(() => ({
    queryKey: [TOP_LEVEL_CACHE_KEY, patientId()],
    queryFn: () => fetchJSON(getOdontogramUrl(patientId())),
    placeholderData: {
      acceptedTreatmentVisits: [],
      archivedTreatmentPlans: [],
      stagingTreatmentPlans: [],
      conditionChartingGroups: [],
      completedTreatmentVisits: [],
      teethSlotStatus: { mandibular: [], maxillary: [] },
    },
  }));
}

export function createOdontogramDataInvalidateMutation<
  TData,
  TVariables extends { skipOdontogramInvalidation?: boolean; patientId: PatientId },
>(mutationFn: () => MutationFunction<TData, TVariables>) {
  const client = useQueryClient();
  return createMutation<TData, Error, TVariables>(() => ({
    mutationFn: mutationFn(),
    onSuccess(data: TData, variables: TVariables) {
      if (!variables.skipOdontogramInvalidation) {
        return client.invalidateQueries({ queryKey: [TOP_LEVEL_CACHE_KEY, variables.patientId] });
      }
    },
  }));
}

export function createOdontogramMutationUpdatingOnSuccess<
  Op extends (variables: Variables, ...args: unknown[]) => Promise<unknown>,
  Variables extends { patientId: PatientId } = Parameters<Op>[0],
>(
  updater: (
    old: OdontogramData,
    mutationResponse: Awaited<ReturnType<Op>>,
    mutationVariables: Variables,
  ) => OdontogramData,
) {
  const client = useQueryClient();
  return (data: Awaited<ReturnType<Op>>, variables: Variables) => {
    client.setQueryData([TOP_LEVEL_CACHE_KEY, variables.patientId], (old: OdontogramData) => {
      return updater(old, data, variables);
    });
  };
}
