import { onCleanup, onMount, type ComponentProps, type JSXElement } from "solid-js";
import { Portal } from "solid-js/web";

import clsx from "~/util/html/style";

type DialogProps = Pick<ComponentProps<"dialog">, "children" | "class" | "style"> & {
  onClose?:
    | ((event: Event & { currentTarget: HTMLDialogElement; target: Element }) => void)
    | undefined;
};
type ConfirmModalProps = {
  message: JSXElement;
  onCancel: (confirm: false) => void;
  onConfirm: (confirm: true) => void;
};

export const DIALOG_MODAL_ID = "9dfb4e88-230c-4321-bfc1-d454252534ed";

/**
 * @description Applies styles to the HTML document to lock body scroll while a dialog is open.
 * @returns A cleanup function when the dialog closes
 */
export function applyDocumentStyleForModal(): () => void {
  const { documentElement } = document;
  Object.assign(documentElement.style, {
    // This is a calculation for the "width of a scrollbar" on OSs that have scrollbars. This
    // minor detail prevents layout shift when overflow is hidden. (It should be zero for OS
    // configurations without scrollbars).
    paddingRight: `${window.innerWidth - documentElement.clientWidth}px`,
    overflow: "hidden",
  });
  return () => {
    Object.assign(documentElement.style, { paddingRight: "", overflow: "" });
  };
}

export function Dialog(props: DialogProps) {
  let dialogRef: HTMLDialogElement | undefined;
  onMount(() => {
    const cleanup = applyDocumentStyleForModal();
    dialogRef!.showModal();
    onCleanup(() => {
      dialogRef!.close();
      cleanup();
    });
  });
  return (
    <Portal>
      <div class="fixed inset-0 z-dialog flex items-center justify-center bg-black bg-opacity-50 p-6">
        <dialog
          ref={dialogRef}
          id={DIALOG_MODAL_ID}
          class={clsx(
            props.class,
            "max-h-full w-full overflow-y-auto rounded-lg bg-white px-8 py-6 shadow-lg",
          )}
          style={props.style}
          onClose={(event) => props.onClose?.(event)}
        >
          {props.children}
        </dialog>
      </div>
    </Portal>
  );
}

export function ConfirmModal(props: ConfirmModalProps) {
  const handleCancel = () => props.onCancel(false);
  return (
    <Dialog class="max-w-md" onClose={handleCancel}>
      <h1 class="text-lg font-semibold">{props.message}</h1>
      <footer class="mt-6 flex items-center justify-end gap-2">
        <button
          type="button"
          class="whitespace-nowrap rounded-full border border-brand-subtle px-5 py-2 text-base font-medium text-typography-primary transition ease-in-out hover:border-brand-secondary hover:bg-brand-light"
          autofocus
          onClick={handleCancel}
        >
          Cancel
        </button>
        <button
          type="button"
          class="whitespace-nowrap rounded-full border border-brand-danger px-5 py-2 text-base font-medium transition ease-in-out hover:bg-brand-danger-light"
          onClick={() => props.onConfirm(true)}
        >
          Confirm
        </button>
      </footer>
    </Dialog>
  );
}
