import type { JSX } from "solid-js";
import {
  QueryClient,
  MutationCache,
  QueryCache,
  QueryClientProvider as InnerClientProvider,
} from "@tanstack/solid-query";

import { captureException, withScope } from "~/observability";

const CLIENT = new QueryClient({
  queryCache: new QueryCache({
    onError: (err, { queryHash }) => {
      withScope((scope) => {
        scope.setContext("query", { queryHash });
        scope.setFingerprint([queryHash.replaceAll(/[0-9]/g, "0")]);
        captureException(err);
      });
    },
  }),
  mutationCache: new MutationCache({
    onError: (err, variables, context, { mutationId, state, options }) => {
      withScope((scope) => {
        scope.setContext("mutation", { mutationId, variables: state.variables });
        if (options.mutationKey) {
          // Duplicate to prevent modification
          scope.setFingerprint(Array.from(options.mutationKey as string[]));
        }
        captureException(err);
      });
    },
  }),
  defaultOptions: {
    queries: { reconcile: "id" },
  },
});

export function QueryClientProvider(props: { children: JSX.Element }) {
  return <InnerClientProvider client={CLIENT}>{props.children}</InnerClientProvider>;
}
