import { SolidController } from "~/controllers";
import { CaptureProfilePhoto } from "~/patient/profile_photo";

export default class CapturePatientProfilePictureController extends SolidController {
  static targets = ["upload"];

  root() {
    const controller = this as unknown as { uploadTarget: HTMLInputElement };
    return <CaptureProfilePhoto fileUpload={controller.uploadTarget} />;
  }
}
