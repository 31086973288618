import { Controller } from "~/controllers";
import { createPopover } from "~/util/html/popover";
import { Turbo } from "@hotwired/turbo-rails";

const DEBOUNCE_MS = 300;

export default class extends Controller {
  static targets = [
    "input",
    "popover",
    "hiddenInput",
    "selectedTags",
    "tagTemplate",
    "tag",
    "tagLabel",
  ];
  static values = {
    typeaheadUrl: String,
    typeaheadTurboFrameId: String,
    queryParam: String,
    multiple: Boolean,
  };

  connect() {
    // so will render results if there is a value, w/o having to type new value
    if (this.multipleValue) {
      this.renderTags();
    } else if (this.inputTarget.value.length > 0) {
      this.submit();
    }
  }

  debouncedSubmit = () => {
    clearTimeout(this.timeout);
    this.timeout = setTimeout(this.submit, DEBOUNCE_MS);
  };

  submit = () => {
    const params = new URLSearchParams({
      [this.queryParamValue]: this.inputTarget.value,
      [this.multipleValue ? "selected_object_ids" : "selected_object_id"]: this.multipleValue
        ? Array.from(this.hiddenInputTarget.selectedOptions)
            .map((opt) => opt.value)
            .join(",")
        : this.hiddenInputTarget.value,
    });

    Turbo.visit(`${this.typeaheadUrlValue}?${params.toString()}`, {
      frame: this.typeaheadTurboFrameIdValue,
    });
  };

  openPopover = () => {
    this.inputTarget.setAttribute("aria-expanded", "true");
    this.cleanup = createPopover({
      trigger: this.inputTarget,
      popover: this.popoverTarget,
    });
  };

  closePopover = () => {
    this.inputTarget.setAttribute("aria-expanded", "false");
    this.cleanup?.();
  };

  selectItem = (event) => {
    const { value, label } = event.params;

    if (this.multipleValue) {
      // Check if value already exists
      const existingOption = Array.from(this.hiddenInputTarget.options).find(
        (opt) => opt.value === value,
      );

      if (existingOption) {
        // If already selected, remove it
        existingOption.remove();
      } else {
        // Add to hidden input
        const option = document.createElement("option");
        option.value = value;
        option.text = value;
        option.selected = true;
        this.hiddenInputTarget.appendChild(option);
      }

      // Clear search input
      this.inputTarget.value = "";

      // Render tags
      this.renderTags();
    } else {
      this.hiddenInputTarget.value = value;
      this.inputTarget.value = label;
      this.inputTarget.blur();
    }

    // Update search results
    this.submit();
  };

  removeTag(event) {
    const value = event.currentTarget.dataset.value;

    // Remove from hidden input
    const option = Array.from(this.hiddenInputTarget.options).find((opt) => opt.value === value);
    if (option) option.remove();

    // Re-render tags
    this.renderTags();

    // Update search results
    this.submit();
  }

  renderTags() {
    if (!this.multipleValue) return;
    if (!this.hasSelectedTagsTarget) return;

    // Remove existing tags
    this.tagTargets.forEach((tag) => tag.remove());

    // Add new tags
    Array.from(this.hiddenInputTarget.selectedOptions).forEach((option) => {
      const newTag = this.tagTemplateTarget.content.cloneNode(true);
      const labelElement = newTag.querySelector('[data-ui--combobox-target="tagLabel"]');
      const removeButton = newTag.querySelector("button");

      labelElement.textContent = option.text;
      removeButton.dataset.value = option.value;

      this.selectedTagsTarget.appendChild(newTag);
    });
  }

  clearValue = () => {
    if (!this.multipleValue) {
      this.hiddenInputTarget.value = "";
    }
  };

  disconnect() {
    this.cleanup?.();
    clearTimeout(this.timeout);
  }
}
