import { Controller } from "~/controllers";
import { setSearchParam } from "~/util/url";

export default class extends Controller {
  connect() {
    window.addEventListener("message", this.handleMessage);
  }

  disconnect() {
    window.removeEventListener("message", this.handleMessage);
  }

  handleMessage = (event) => {
    if (event.source !== this.element.contentWindow) return;
    if (!event.data?.metabase) return;

    const { metabase } = event.data;
    if (metabase.type === "location") {
      // so don't show the return_to param w/ the internal metabase visibility options
      if (metabase.location.search.includes("initial_redirect=true")) return;

      setSearchParam(
        "location",
        metabase.location.pathname + metabase.location.search + metabase.location.hash,
      );
    }
  };
}
