import { createMutation } from "@tanstack/solid-query";
import { fetchJSON, getPatientBaseUrl, type PatientId } from "~/api";
import {
  createOdontogramDataInvalidateMutation,
  createOdontogramMutationUpdatingOnSuccess,
} from "~/api/odontogram";
import type { TreatmentVisit } from "~/api/treatment_visits";

export type TreatmentPlanId = `tplan_${string}`;
export type TreatmentPlan = {
  id: TreatmentPlanId;
  name: string;
  totalCost: number;
  createdAt: string;
  treatmentVisits: TreatmentVisit[];
};

function getPatientUrl(options: {
  patientId: PatientId;
  treatmentPlanId?: TreatmentPlanId;
}): string {
  const suffixId = options.treatmentPlanId ? `/${options.treatmentPlanId}` : "";
  return `${getPatientBaseUrl(options.patientId)}/treatment_plans${suffixId}`;
}

function addTreatmentPlan(options: { patientId: PatientId }): Promise<{ id: TreatmentPlanId }> {
  return fetchJSON(getPatientUrl(options), { method: "POST" });
}

export function createAddTreatmentPlanMutation() {
  return createOdontogramDataInvalidateMutation(() => addTreatmentPlan);
}

function renameTreatmentPlan(options: {
  patientId: PatientId;
  treatmentPlanId: TreatmentPlanId;
  name: string;
}): Promise<void> {
  const body = new FormData();
  body.append("treatment_plan[name]", options.name);
  return fetchJSON(getPatientUrl(options), { method: "PUT", body });
}

export function createRenameTreatmentPlanMutation() {
  const renameTreatmentPlanNameOnSuccess = createOdontogramMutationUpdatingOnSuccess<
    typeof renameTreatmentPlan
  >((old, response, { name, treatmentPlanId }) => ({
    ...old,
    stagingTreatmentPlans: old.stagingTreatmentPlans.map((plan) => {
      return plan.id === treatmentPlanId ? { ...plan, name } : plan;
    }),
  }));
  return createMutation(() => ({
    mutationFn: renameTreatmentPlan,
    onSuccess: renameTreatmentPlanNameOnSuccess,
  }));
}

function rejectTreatmentPlan(options: { patientId: PatientId; treatmentPlanId: TreatmentPlanId }) {
  return fetchJSON(getPatientUrl(options), { method: "DELETE" });
}

export function createRejectTreatmentPlanMutation() {
  return createOdontogramDataInvalidateMutation(() => rejectTreatmentPlan);
}

function unrejectTreatmentPlan(options: {
  patientId: PatientId;
  treatmentPlanId: TreatmentPlanId;
}) {
  return fetchJSON(`${getPatientUrl(options)}/unreject`, { method: "PUT" });
}

export function createUnrejectTreatmentPlanMutation() {
  return createOdontogramDataInvalidateMutation(() => unrejectTreatmentPlan);
}

function acceptTreatmentPlan(options: { patientId: PatientId; treatmentPlanId: TreatmentPlanId }) {
  return fetchJSON(`${getPatientUrl(options)}/accept`, { method: "PUT" });
}

export function createAcceptTreatmentPlanMutation() {
  return createOdontogramDataInvalidateMutation(() => acceptTreatmentPlan);
}

function duplicateTreatmentPlan(options: {
  patientId: PatientId;
  treatmentPlanId: TreatmentPlanId;
}): Promise<{ id: TreatmentPlanId }> {
  return fetchJSON(`${getPatientUrl(options)}/duplicate`, { method: "POST" });
}

export function createDuplicateTreatmentPlanMutation() {
  return createOdontogramDataInvalidateMutation(() => duplicateTreatmentPlan);
}
