import { createMutation } from "@tanstack/solid-query";

import { arrayHasMatchingId, immutablyUpdateItemFromIfFound } from "~/util/data_structures";
import { fetchJSON, getPatientBaseUrl, type PatientId } from "~/api";
import {
  createOdontogramDataInvalidateMutation,
  createOdontogramMutationUpdatingOnSuccess,
} from "~/api/odontogram";
import type { OdontogramData } from "~/api/odontogram";
import type { TreatmentVisit, TreatmentVisitId } from "~/api/treatment_visits";
import type { TreatmentPlanId } from "~/api/treatment_plans";
import type { UniversalId } from "~/charting/odontogram/dentition";

export type SurfaceMaterial = "composite" | "amalgam";
export type ToothSurface = "F" | "L" | "D" | "M" | "O";
type TreatmentSiteKind =
  | { type: "unknown" | "crown" | "canal" | "extraction" }
  | { type: "bridge"; secondaryToothUniversal: UniversalId }
  | {
      type: "surface";
      surfaces: ToothSurface[];
      material: SurfaceMaterial;
    };
export type TreatmentSite = TreatmentSiteKind & {
  toothUniversal: UniversalId;
  description: string;
};
export type ProviderId = `prvdr_${string}`;
export type TreatmentId = `treat_${string}`;
export type Treatment = {
  id: TreatmentId;
  code: string;
  description: string;
  adjustedPatientCost: string;
  netProduction: string;
  insuranceCost: string;
  /** If the treatment does not conceptually use units, this will be `undefined`. Conversely, `null` means unset */
  units?: number | null;
  site: TreatmentSite;
  provider: { id: ProviderId; fullName: string } | null;
};
export type WhereTreatmentAdd =
  | { type: "staging"; treatmentPlanId: TreatmentPlanId }
  | { type: "accepted"; treatmentVisitId: TreatmentVisitId };

function getTreatmentsUrl(patientId: PatientId) {
  return `${getPatientBaseUrl(patientId)}/treatments`;
}

function getTreatmentUrl(options: { patientId: PatientId; treatmentId: TreatmentId }) {
  return `${getTreatmentsUrl(options.patientId)}/${options.treatmentId}`;
}

function findAndUpdateMatchingVisit(
  old: OdontogramData,
  treatmentId: TreatmentId,
  updater: (visit: TreatmentVisit) => TreatmentVisit,
): OdontogramData {
  const visitHasTreatment = (visit: TreatmentVisit) =>
    arrayHasMatchingId(visit.treatments, treatmentId);
  if (old.acceptedTreatmentVisits.some(visitHasTreatment)) {
    return {
      ...old,
      acceptedTreatmentVisits: old.acceptedTreatmentVisits.map(updater),
    };
  }
  return {
    ...old,
    stagingTreatmentPlans: old.stagingTreatmentPlans.map((plan) => {
      return plan.treatmentVisits.some(visitHasTreatment)
        ? { ...plan, treatmentVisits: plan.treatmentVisits.map(updater) }
        : plan;
    }),
  };
}

function removeTreatment(options: { patientId: PatientId; treatmentId: TreatmentId }) {
  return fetchJSON(getTreatmentUrl(options), { method: "DELETE" });
}

export function createRemoveTreatmentMutation() {
  return createOdontogramDataInvalidateMutation(() => removeTreatment);
}

function addTreatment(options: {
  patientId: PatientId;
  where: WhereTreatmentAdd;
  treatmentCatalogId: `tcat_${string}`;
  selections: {
    universal: UniversalId;
    secondaryUniversal?: UniversalId;
    surfaces?: ToothSurface[];
  }[];
}) {
  const body = new FormData();
  body.append("treatment_catalog_id", options.treatmentCatalogId);
  body.append("selections", JSON.stringify(options.selections));
  const { where } = options;
  if (where.type === "staging") {
    body.append("staging_treatment_plan_id", where.treatmentPlanId);
  } else {
    body.append("on_accepted_treatment_visit", where.treatmentVisitId);
  }
  return fetchJSON(getTreatmentsUrl(options.patientId), { method: "POST", body });
}

export function createAddTreatmentMutation() {
  return createOdontogramDataInvalidateMutation(() => addTreatment);
}

function editTreatmentAttributes(options: {
  patientId: PatientId;
  treatmentId: TreatmentId;
  units?: number | null;
  providerId?: ProviderId;
}): Promise<{ treatment: Treatment }> {
  const body = new FormData();
  if (options.units !== undefined) {
    body.append("units", options.units?.toString() || "");
  }
  if (options.providerId !== undefined) {
    body.append("provider_id", options.providerId);
  }
  return fetchJSON(getTreatmentUrl(options), { method: "PUT", body });
}

export function createEditTreatmentAttributesMutation(options?: { onSuccess?: () => void }) {
  const editTreatmentAttributesOnSuccess = createOdontogramMutationUpdatingOnSuccess<
    typeof editTreatmentAttributes
  >((old, { treatment }, { treatmentId }) => {
    options?.onSuccess?.();
    const updateAttrs = () => treatment;
    return findAndUpdateMatchingVisit(old, treatmentId, (visit) =>
      immutablyUpdateItemFromIfFound(visit, "treatments", treatmentId, updateAttrs),
    );
  });
  return createMutation(() => ({
    mutationFn: editTreatmentAttributes,
    onSuccess: editTreatmentAttributesOnSuccess,
  }));
}
