import { Turbo } from "@hotwired/turbo-rails";
import "@gorails/ninja-keys";

import { Controller } from "~/controllers";
import { UI_MODAL_FRAME } from "~/util/url";
import { fetchJSON } from "~/api";

const isMac = navigator.platform.toUpperCase().includes("MAC") >= 0;
function createCommand(title, hotkey, icon, handler) {
  return {
    id: title,
    title,
    hotkey: isMac ? hotkey : hotkey.replace("⌘", "ctrl").replace("⌥", "alt"),
    icon,
    handler,
  };
}

// TODO: cleanup, probably should build subset of ninja-keys ourselves
export default class extends Controller {
  static targets = ["ninjakeys"];
  static values = {
    faceFinderHref: String,
  };

  commands = [
    createCommand(
      "Create patient",
      "⌥+n",
      '<svg xmlns="http://www.w3.org/2000/svg" class="ninja-icon" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2"><path stroke-linecap="round" stroke-linejoin="round" d="M12 4v16m8-8H4" /></svg>',
      () => {
        Turbo.visit("/patients/new");
      },
    ),
    createCommand(
      "Face finder",
      "⌘+p",
      '<svg xmlns="http://www.w3.org/2000/svg" class="ninja-icon" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true" class="aria-current:hidden"> <path stroke-linecap="round" stroke-linejoin="round" d="M15.182 15.182a4.5 4.5 0 01-6.364 0M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z"></path> </svg>',
      () => {
        Turbo.visit(this.faceFinderHrefValue, { frame: UI_MODAL_FRAME });
      },
    ),
  ];

  connect() {
    this.ninjakeysTarget.data = this.commands;

    this.element.addEventListener("change", (event) => {
      // detail = {search: 'your search query', actions: Array<NinjaAction>}
      if (event.detail?.search) {
        this.searchPatients(event.detail.search);
      } else {
        this.ninjakeysTarget.data = this.commands;
      }
    });
  }

  openPalette() {
    this.ninjakeysTarget.open();
  }

  searchPatients(query) {
    if (!query) {
      return;
    }

    const params = new URLSearchParams({ query });
    const thisSearch = (this.mostRecentFetch = fetchJSON(
      `/patients/search.json?${params.toString()}`,
    ));
    thisSearch
      .catch(() => this.commands)
      .then((patients) => {
        if (this.mostRecentFetch === thisSearch) {
          this.updateCommands(patients, query);
        }
      });
  }

  updateCommands(patients, query) {
    const patientCommands = patients.map((patient) => {
      return {
        id: `patient-${patient.id}`,
        title: patient.formattedDob
          ? `${patient.firstName} ${patient.lastName} (${patient.formattedDob})`
          : `${patient.firstName} ${patient.lastName}`,
        handler: () => {
          Turbo.visit(`/patients/${patient.id}`);
        },
        keywords: query,
      };
    });

    this.ninjakeysTarget.data = [
      ...this.ninjakeysTarget.data
        .filter((cmd) => !cmd.id.startsWith("patient-"))
        .map((cmd) => {
          if (cmd.id.startsWith("CreatePatient") && patientCommands.length === 0) {
            return {
              ...cmd,
              keywords: query,
            };
          } else {
            return {
              ...cmd,
              keywords: "",
            };
          }
        }),
      ...patientCommands,
    ];
  }
}
