import { render } from "solid-js/web";

import { TwelveEntrypointWrapper } from "~/util/entrypoint";
import { ConfirmModal } from "~/util/html/dialog";

/**
 * @description Confirm modal for use with Turbo.
 * @example
   <%= button_to(
     "Delete",
     my_path,
     method: :delete, 
     form: {
       data: {turbo_confirm: "Are you sure?"},
     }
   ) %>
 */
export function turboConfirm(message: string): Promise<boolean> {
  return new Promise<boolean>((resolve) => {
    const container = document.createElement("div");
    document.body.appendChild(container);
    const cleanup = render(() => {
      const handleExit = (confirm: boolean) => {
        resolve(confirm);
        cleanup();
        container.remove();
      };
      return (
        <TwelveEntrypointWrapper
          entry={() => (
            <ConfirmModal message={message} onCancel={handleExit} onConfirm={handleExit} />
          )}
        />
      );
    }, container);
  });
}
