import { getCookie } from "typescript-cookie";

type FetchParams = Parameters<(typeof window)["fetch"]>;
type FetchRequestHeaders = Record<string, string>;
type FetchOptions = Omit<NonNullable<FetchParams[1]>, "headers"> & {
  headers?: FetchRequestHeaders;
};
export type PatientId = `patnt_${string}`;

export const API_BASE_PATH = "/api/v1";

function metaContent(name: string) {
  return document.head.querySelector<HTMLMetaElement>(`meta[name="${name}"]`)?.content;
}

function getCSRFToken() {
  const name = metaContent("csrf-param");
  if (name) {
    return getCookie(name) || metaContent("csrf-token");
  }
}

export function getPatientBaseUrl(patientId: PatientId): string {
  return `${API_BASE_PATH}/patients/${patientId}`;
}

export function getOdontogramUrl(patientId: PatientId): string {
  return `${getPatientBaseUrl(patientId)}/odontogram`;
}

export async function fetchJSON<R>(resource: FetchParams[0], options?: FetchOptions): Promise<R> {
  const headers: FetchRequestHeaders = {
    Accept: "application/json",
    "X-Requested-With": "XMLHttpRequest",
    ...options?.headers,
  };
  const token = options?.method !== "GET" && getCSRFToken();
  if (token) {
    headers["X-CSRF-Token"] = token;
  }
  const response = await fetch(resource, {
    ...options,
    headers,
  });
  if (response.ok) {
    return response.json() as Promise<R>;
  }
  throw new Error(`Failed response ${response.status}`);
}
