/** Flip a value's precense in a set */
export function toggleInSet<T>(oldSet: Set<T> | null | undefined, key: T): Set<T> {
  const newSet = new Set(oldSet);
  return newSet.delete(key) ? newSet : newSet.add(key);
}

export function arrayHasMatchingId<I>(array: { id: I }[], id: I): boolean {
  return array.some((item) => item.id === id);
}

function immutablyChange<
  Id,
  Item extends { id: Id },
  T extends { [k in K]: Item[] },
  K extends keyof T,
>(container: T, key: K, id: Id, write: (old: Item[]) => Item[]): T {
  const oldArray = container[key];
  return arrayHasMatchingId(oldArray, id) ? { ...container, [key]: write(oldArray) } : container;
}

/** Traverse an array property of an object and conditionally remove a matching item */
export function immutablyRemoveItemFromIfFound<
  Id,
  Item extends { id: Id },
  T extends { [k in K]: Item[] },
  K extends keyof T,
>(container: T, key: K, id: Id): T {
  return immutablyChange(container, key, id, (old) => old.filter((item) => item.id !== id));
}

/** Traverse an array property of an object and conditionally update a matching item */
export function immutablyUpdateItemFromIfFound<
  Id,
  Item extends { id: Id },
  T extends { [k in K]: Item[] },
  K extends keyof T,
>(container: T, key: K, id: Id, update: (item: Item) => Item): T {
  return immutablyChange<Id, Item, T, K>(container, key, id, (old) =>
    old.map((item) => (item.id === id ? update(item) : item)),
  );
}
