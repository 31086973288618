import { createMutation } from "@tanstack/solid-query";

import { API_BASE_PATH, fetchJSON, getPatientBaseUrl, type PatientId } from "~/api";
import {
  createOdontogramDataInvalidateMutation,
  createOdontogramMutationUpdatingOnSuccess,
} from "~/api/odontogram";
import type { Treatment, TreatmentId } from "~/api/treatments";
import type { TreatmentPlanId } from "~/api/treatment_plans";
import {
  immutablyRemoveItemFromIfFound,
  immutablyUpdateItemFromIfFound,
} from "~/util/data_structures";

type LocationId = `loca_${string}`;
type Location = {
  id: LocationId;
};
type AppointmentId = `appmt_${string}`;
type Appointment = {
  id: AppointmentId;
  startTime: string | null;
  endTime: string | null;
  url: string;
};
export type ClinicalNote = {
  status: "draft" | "complete";
  content: string;
};
export type TreatmentAgreementId = `tagree_${string}`;
export type TreatmentAgreement =
  | { status: "missing" | "not_required" }
  | {
      id: TreatmentAgreementId;
      createdAt: string;
      status: "valid" | "stale" | "treatment_mismatch";
      url: string;
    };
export type TreatmentVisitId = `tvsit_${string}`;
export type TreatmentVisit = {
  id: TreatmentVisitId;
  appointment: Appointment | null;
  location: Location;
  agreement: TreatmentAgreement;
  clinicalNote: ClinicalNote | null;
  treatments: Treatment[];
};

const CLINICAL_NOTES_URL = `${API_BASE_PATH}/clinical_notes`;

function getVisitsUrl(patientId: PatientId) {
  return `${getPatientBaseUrl(patientId)}/treatment_visits`;
}

function getVisitUrl(options: { patientId: PatientId; treatmentVisitId: TreatmentVisitId }) {
  return `${getVisitsUrl(options.patientId)}/${options.treatmentVisitId}`;
}

function addVisit(
  options: {
    patientId: PatientId;
    skipOdontogramInvalidation?: boolean;
  } & (
    | { treatmentPlanId?: never; appointmentLocationId: LocationId }
    | { treatmentPlanId: TreatmentPlanId; appointmentLocationId?: never }
  ),
): Promise<{ id: TreatmentVisitId }> {
  const body = new FormData();
  if (options.treatmentPlanId) {
    body.append("treatment_plan_id", options.treatmentPlanId);
  } else {
    body.append("appointment_location_id", options.appointmentLocationId);
  }
  return fetchJSON(getVisitsUrl(options.patientId), { body, method: "POST" });
}

export function createAddVisitMutation() {
  return createOdontogramDataInvalidateMutation(() => addVisit);
}

function removeVisit(options: { patientId: PatientId; treatmentVisitId: TreatmentVisitId }) {
  return fetchJSON(getVisitUrl(options), { method: "DELETE" });
}

export function createRemoveVisitFromTreatmentPlanMutation() {
  const removeVisitOnSuccess = createOdontogramMutationUpdatingOnSuccess<typeof removeVisit>(
    (old, data, { treatmentVisitId }) => {
      const newAccepted = immutablyRemoveItemFromIfFound(
        old,
        "acceptedTreatmentVisits",
        treatmentVisitId,
      );
      if (newAccepted !== old) {
        return newAccepted;
      }
      return {
        ...old,
        stagingTreatmentPlans: old.stagingTreatmentPlans.map((plan) => {
          return immutablyRemoveItemFromIfFound(plan, "treatmentVisits", treatmentVisitId);
        }),
      };
    },
  );
  return createMutation(() => ({
    mutationFn: removeVisit,
    onSuccess: removeVisitOnSuccess,
  }));
}

function reorderTreatments(options: {
  patientId: PatientId;
  visitsWithTreatments: { id: TreatmentVisitId; treatmentIds: TreatmentId[] }[];
}) {
  const body = new FormData();
  body.append("visit_treatment_ids", JSON.stringify(options.visitsWithTreatments));
  return fetchJSON(`${getVisitsUrl(options.patientId)}/reorder_treatments`, {
    method: "PUT",
    body,
  });
}

export function createReorderTreatmentsMutation() {
  return createOdontogramDataInvalidateMutation(() => reorderTreatments);
}

function updateClinicalNote(options: {
  patientId: PatientId;
  treatmentVisitId: TreatmentVisitId;
  content: string;
  status?: ClinicalNote["status"];
}): Promise<unknown> {
  const body = new FormData();
  body.append("treatment_visit_id", options.treatmentVisitId);
  body.append("note[content]", options.content);
  body.append("note[status]", options.status || "draft");
  return fetchJSON(CLINICAL_NOTES_URL, { body, method: "POST" });
}

export function createUpdateClinicalNoteMutation() {
  const updateVisitOnSuccess = createOdontogramMutationUpdatingOnSuccess<typeof updateClinicalNote>(
    (old, data, { content, status, treatmentVisitId }) => {
      const update = (visit: TreatmentVisit) => ({
        ...visit,
        clinicalNote: { status: status || "draft", content } as TreatmentVisit["clinicalNote"],
      });
      const newAccepted = immutablyUpdateItemFromIfFound(
        old,
        "acceptedTreatmentVisits",
        treatmentVisitId,
        update,
      );
      if (newAccepted !== old) {
        return newAccepted;
      }
      return immutablyUpdateItemFromIfFound(
        old,
        "completedTreatmentVisits",
        treatmentVisitId,
        update,
      );
    },
  );
  return createMutation(() => ({
    mutationFn: updateClinicalNote,
    onSuccess: updateVisitOnSuccess,
  }));
}
