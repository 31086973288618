import type { PatientId } from "~/api";
import { SolidController } from "~/controllers";
import { SignatureCollection } from "~/patient/signature";

export default class SignatureController extends SolidController {
  static values = {
    patient: Object,
    captureId: String,
  };

  root() {
    const controller = this as unknown as {
      captureIdValue: string;
      patientValue: { fullName: string; id: PatientId };
    };
    return (
      <SignatureCollection
        captureId={controller.captureIdValue}
        patient={controller.patientValue}
      />
    );
  }
}
