import { Controller } from "~/controllers";

export default class extends Controller {
  static targets = ["submitButton"];

  connect() {
    this.element.addEventListener("keydown", (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        this.submitForm();
      }
    });

    // Add click handler for the submit button if it exists
    const submitButton = this.element.querySelector('button[type="submit"]');
    if (submitButton) {
      submitButton.addEventListener("click", () => {
        setTimeout(() => {
          this.element.reset();
        }, 0);
      });
    }
  }

  submitForm() {
    this.element.requestSubmit();
    setTimeout(() => {
      this.element.reset();
    }, 0);
  }
}
