// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { init, setUser, setTag, captureException as sentryCaptureException } from "@sentry/browser";

function getMetaInfo(name: string): unknown {
  const content = document.head.querySelector<HTMLMetaElement>(`meta[name="${name}"]`)?.content;
  try {
    return content && JSON.parse(content);
  } catch {
    // Ignore json parse errors
  }
}

let captureFn: (...params: Parameters<typeof sentryCaptureException>) => void = (error, hint) => {
  console.error(error, hint);
};

if (import.meta.env.PUBLIC_SENTRY_DSN) {
  const envInfo = getMetaInfo("env-info") as { name: string; release: string | null } | undefined;

  init({
    dsn: import.meta.env.PUBLIC_SENTRY_DSN,
    environment: envInfo?.name,
    release: import.meta.env.PUBLIC_RELEASE_GIT_HASH,
    beforeSend(event, hint) {
      const message = (hint.originalException as null | { message?: string })?.message;
      switch (message) {
        case "The user aborted a request.": {
          console.warn(message);
          return null;
        }
      }
      return event;
    },
  });
  captureFn = sentryCaptureException;

  if (envInfo?.release) {
    setTag("server_release", envInfo.release);
  }

  const userInfo = getMetaInfo("user-info") as { id: string; email: string } | undefined;
  if (userInfo) {
    setUser(userInfo);
  }
}

export const captureException = captureFn;
