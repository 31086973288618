import { createSignal, onCleanup, onMount, Show } from "solid-js";

import { canvasToPNGBlob } from "~/form/blob";

type Props = {
  fileUpload: HTMLInputElement;
};
type CaptureState =
  | { type: "video-capturing" | "encoding" }
  | { type: "ready-to-upload"; file: File; dataURL: string };

function activateVideo(video: HTMLVideoElement) {
  window.navigator.mediaDevices
    .getUserMedia({
      video: {
        aspectRatio: { ideal: 1.25 }, // 5:4 video for less data
      },
      audio: false,
    })
    .then((stream) => {
      video.classList.remove("aspect-[5/4]");
      video.srcObject = stream;
      video.play();
    });
}

async function getPNG(canvas: HTMLCanvasElement, video: HTMLVideoElement) {
  canvas.width = video.videoWidth;
  canvas.height = video.videoHeight;
  const context = canvas.getContext("2d")!;
  context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
  return {
    file: new File([await canvasToPNGBlob(canvas)], "profile_photo.png", { type: "image/png" }),
    dataURL: canvas.toDataURL("image/png"),
  };
}

function addFileToInput(file: File, input: HTMLInputElement) {
  const transfer = new DataTransfer();
  transfer.items.add(file);
  input.files = transfer.files;
}

export function CaptureProfilePhoto(props: Props) {
  const canvasRef = (<canvas />) as HTMLCanvasElement;
  let videoRef: HTMLVideoElement | undefined;

  const [captureState, setCaptureState] = createSignal<CaptureState>({ type: "video-capturing" });

  onMount(() => {
    activateVideo(videoRef!);

    onCleanup(() => {
      (videoRef!.srcObject as MediaStream).getTracks().forEach((track) => track.stop());
      videoRef!.srcObject = null;
    });
  });

  return (
    <>
      <div class="relative">
        <video ref={videoRef} disablepictureinpicture class="aspect-[5/4] w-full" />

        <Show
          when={(() => {
            const state = captureState();
            return state.type === "ready-to-upload" && state;
          })()}
          keyed
        >
          {({ file, dataURL }) => {
            addFileToInput(file, props.fileUpload);
            return (
              <>
                <img alt="" class="absolute inset-0" src={dataURL} />
                <div
                  aria-hidden="true"
                  class="absolute inset-0 bg-white opacity-0 transition-opacity duration-1000 starting:opacity-100"
                />
              </>
            );
          }}
        </Show>

        <div
          class="absolute inset-0 bg-white"
          style={{
            "mask-image":
              "radial-gradient(circle closest-side at center, rgba(0, 0, 0, 0) 100%, rgba(0, 0, 0, 0.8) 100%)",
          }}
          aria-hidden="true"
        />
      </div>

      <footer class="flex items-center gap-2 *:flex-1">
        <Show
          when={captureState().type === "ready-to-upload"}
          fallback={
            <button
              type="button"
              class="brand-button-bg mt-7 rounded-full px-5 py-2 text-white transition hover:bg-brand-active"
              onClick={() => {
                setCaptureState({ type: "encoding" });
                getPNG(canvasRef, videoRef!).then(({ file, dataURL }) => {
                  setCaptureState({ type: "ready-to-upload", file, dataURL });
                });
              }}
              disabled={captureState().type === "encoding"}
            >
              Capture
            </button>
          }
        >
          <button
            type="button"
            class="mt-7 rounded-full border border-brand-subtle px-5 py-2 text-typography-primary transition hover:border-brand-secondary hover:bg-brand-light"
            onClick={() => setCaptureState({ type: "video-capturing" })}
          >
            Re-do
          </button>
          <button
            type="submit"
            class="brand-button-bg mt-7 rounded-full px-5 py-2 text-white transition hover:bg-brand-active"
          >
            Complete
          </button>
        </Show>
      </footer>
    </>
  );
}
