import { Controller } from "~/controllers";
import { UI_MODAL_FRAME } from "~/util/url";
import { DIALOG_MODAL_ID } from "~/util/html/dialog";

export default class extends Controller {
  connect() {
    // Because click handlers inside allow-listed elements often tear down
    // DOM and cleanup (think close buttons in a modal), this click handler
    // fires on capture phase so as to be able to check `contains()` before
    // that happens.
    document.addEventListener("click", this.handleDocumentClick, true);
  }

  disconnect() {
    document.removeEventListener("click", this.handleDocumentClick, true);
  }

  handleDocumentClick = (event: MouseEvent) => {
    if (!this.#allowListedElementsContain(event.target as Node)) {
      this.element.dispatchEvent(new CustomEvent("click-outside"));
    }
  };

  #allowListedElementsContain(target: Node) {
    return (
      this.element.contains(target) ||
      document.getElementById(UI_MODAL_FRAME)?.contains(target) ||
      document.getElementById(DIALOG_MODAL_ID)?.contains(target)
    );
  }
}
