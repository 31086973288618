import {
  createContext,
  createSignal,
  createSelector,
  createMemo,
  onMount,
  onCleanup,
  useContext,
  type Accessor,
  type JSXElement,
} from "solid-js";
import type { RichTextSelectionRange } from "trix";

import {
  createEditTreatmentAttributesMutation,
  type ProviderId,
  type TreatmentId,
} from "~/api/treatments";
import {
  createUpdateClinicalNoteMutation,
  type ClinicalNote,
  type TreatmentVisitId,
} from "~/api/treatment_visits";
import { createRenameTreatmentPlanMutation, type TreatmentPlanId } from "~/api/treatment_plans";
import type { PatientId } from "~/api";

type DraftClinicalNote = { value: string; range?: RichTextSelectionRange };
type TabState =
  | {
      tab: "Rejected" | "Planning" | "Scheduled";
      clinicalNoteSelection?: never;
    }
  | {
      tab: "History";
      clinicalNoteSelection?: {
        id: TreatmentVisitId;
        where: "popout" | "inline";
        draft?: DraftClinicalNote;
      };
    };
type TabKind = TabState["tab"];
type Attribute = "units" | "provider";
type InlineEditState = null | {
  type: "editing" | "pending";
  attribute: Attribute;
  treatmentId: TreatmentId;
};
type WaitPhase = "invisible" | "visible" | "hidden";
type ImageLoadingState = {
  realtimeWaitPhase: WaitPhase;
  doneMs: number | null;
  startMs: number | null;
};
export type OdontogramEditContext = ReturnType<typeof createInlineTreatmentContext> &
  ReturnType<typeof createStickyState> &
  ReturnType<typeof createAsyncTeeth> &
  ReturnType<typeof createTreatmentPlanContext> &
  ReturnType<typeof createTabState> & {
    clinicalNoteTemplates: Accessor<ProviderProps["clinicalNoteTemplates"]>;
    providers: Accessor<ProviderProps["providers"]>;
    maxStagingTreatmentPlanCount: Accessor<ProviderProps["maxStagingTreatmentPlanCount"]>;
  };
type ProviderProps = {
  clinicalNoteTemplates: { name: string; content: string }[];
  providers: { id: ProviderId; fullName: string }[];
  patientId: PatientId;
  maxStagingTreatmentPlanCount: number;
  children: JSXElement;
};

const STICKY_STORAGE_KEY = "twelve-sticky-odontogram";
const UNLOCKED_STORAGE_VALUE = "unlocked";
const CONTEXT = createContext<OdontogramEditContext>();
const FAST_IMAGES_MS = 10;
const MINIMUM_WAIT_IMAGES_MS = 1_000;

export function useOdontogramEditContext(): OdontogramEditContext {
  return useContext(CONTEXT)!;
}

function createInlineTreatmentContext(props: ProviderProps) {
  const [inlineEditing, setInlineEditing] = createSignal<null | {
    treatmentId: TreatmentId;
    attribute: Attribute;
  }>(null);
  const editTreatmentAttributesMutation = createEditTreatmentAttributesMutation({
    onSuccess() {
      setInlineEditing(null);
    },
  });
  const inlineEditState = createMemo<InlineEditState>(() => {
    const { variables, isPending } = editTreatmentAttributesMutation;
    if (isPending) {
      return {
        type: "pending",
        treatmentId: variables.treatmentId,
        attribute: variables.units !== undefined ? "units" : "provider",
      };
    }
    const inline = inlineEditing();
    return (
      inline && { type: "editing", treatmentId: inline.treatmentId, attribute: inline.attribute }
    );
  });
  const isTreatmentRow = createSelector(
    inlineEditState,
    (key: [TreatmentId, Attribute], editState) =>
      editState?.treatmentId === key[0] && editState.attribute === key[1],
  );
  return {
    inlineEditingTreatment(
      ...args: [treatmentId: TreatmentId, attribute: Attribute]
    ): InlineEditState {
      return isTreatmentRow(args) ? inlineEditState() : null;
    },
    startInlineEditTreatment(treatmentId: TreatmentId, attribute: Attribute) {
      setInlineEditing({ treatmentId, attribute });
    },
    cancelInlineEditTreatment() {
      setInlineEditing(null);
    },
    completeInlineEditTreatment(
      treatmentId: TreatmentId,
      newAttributes:
        | { providerId: ProviderId; units?: never }
        | { providerId?: never; units: number | null },
    ) {
      editTreatmentAttributesMutation.mutate({
        patientId: props.patientId,
        treatmentId,
        ...newAttributes,
      });
    },
  };
}

function createTabState(props: ProviderProps) {
  const [tabState, setTabState] = createSignal<TabState>({ tab: "Scheduled" });
  const isActiveTab = createSelector(tabState, (tab: TabKind, state) => state.tab === tab);
  const isActiveClinicalNoteTreatmentVisit = createSelector(
    tabState,
    (treatmentVisitId: TreatmentVisitId, state) =>
      state.clinicalNoteSelection?.id === treatmentVisitId,
  );

  const updateClinicalNoteMutation = createUpdateClinicalNoteMutation();

  function toggleSelectedHistoryVisit(treatmentVisitId: null): void;
  function toggleSelectedHistoryVisit(
    treatmentVisitId: TreatmentVisitId,
    draft?: DraftClinicalNote,
  ): void;
  function toggleSelectedHistoryVisit(
    treatmentVisitId: TreatmentVisitId | null,
    draft?: DraftClinicalNote,
  ): void {
    if (!treatmentVisitId) {
      setTabState({ tab: "History" });
      return;
    }

    setTabState<TabState>((currentState) => {
      const alreadyHistorySameIdPopout =
        currentState.tab === "History" &&
        currentState.clinicalNoteSelection?.id === treatmentVisitId &&
        currentState.clinicalNoteSelection.where === "popout";
      return {
        tab: "History",
        clinicalNoteSelection: {
          id: treatmentVisitId,
          where: alreadyHistorySameIdPopout ? "inline" : "popout",
          draft,
        },
      };
    });
  }

  return {
    isActiveTab,
    isActiveClinicalNoteTreatmentVisit,
    clinicalNoteSelectionState() {
      const selection = tabState().clinicalNoteSelection;
      return (
        selection && {
          ...selection,
          isPending: updateClinicalNoteMutation.isPending,
        }
      );
    },
    addOrUpdateClinicalNote(content: string, status: ClinicalNote["status"]) {
      const { clinicalNoteSelection } = tabState();
      if (!clinicalNoteSelection) {
        throw new Error(`Missing treatment visit id on tab ${tabState().tab}`);
      }
      const { id: treatmentVisitId } = clinicalNoteSelection;
      updateClinicalNoteMutation
        .mutateAsync({ patientId: props.patientId, treatmentVisitId, content, status })
        .then(() => {
          setTabState((old) =>
            old.clinicalNoteSelection?.id === treatmentVisitId ? { tab: old.tab } : old,
          );
        });
    },
    toggleSelectedHistoryVisit,
    selectTab(tab: TabKind) {
      setTabState({ tab });
    },
  };
}

function getStickyFromStorage(): boolean {
  return window.localStorage.getItem(STICKY_STORAGE_KEY) !== UNLOCKED_STORAGE_VALUE;
}

function setStickyInStorage(value: boolean) {
  if (value) {
    window.localStorage.removeItem(STICKY_STORAGE_KEY);
  } else {
    window.localStorage.setItem(STICKY_STORAGE_KEY, UNLOCKED_STORAGE_VALUE);
  }
}

function createStickyState() {
  const [isSticky, setIsSticky] = createSignal(getStickyFromStorage());
  const scrolledFarEnough = () => window.scrollY >= 24;
  const [isScrolledDown, setIsScrolledFarEnough] = createSignal(scrolledFarEnough());

  onMount(() => {
    const scrollCallback = () => setIsScrolledFarEnough(scrolledFarEnough());
    document.addEventListener("scroll", scrollCallback);
    onCleanup(() => {
      document.removeEventListener("scroll", scrollCallback);
    });
  });

  return {
    isScrolledDown,
    isSticky,
    toggleIsSticky() {
      const newValue = !isSticky();
      setStickyInStorage(newValue);
      setIsSticky(newValue);
    },
  };
}

function createAsyncTeeth() {
  let total = 0;
  let complete = 0;
  const [loading, setLoading] = createSignal<ImageLoadingState>({
    realtimeWaitPhase: "invisible",
    doneMs: null,
    startMs: null,
  });

  const loadCallback = () => {
    complete += 1;
    // Once we reach the total, we're done forever, even if something registers later
    // (that should never happen).
    if (complete === total) {
      setLoading((old) => {
        return old.doneMs === null ? { ...old, doneMs: Date.now() } : old;
      });
    }
  };

  const teethImagePlaceholderPhase = createMemo<WaitPhase>(() => {
    const { doneMs, startMs, realtimeWaitPhase } = loading();
    if (doneMs === null || startMs === null) {
      // If we're not done loading images, keep placeholders visible, regardless of realtime phase
      return realtimeWaitPhase === "hidden" ? "visible" : realtimeWaitPhase;
    }
    // If we're done loading images and the load was fast enough, go right to
    // hidden to prevent a flash for better loading UX. Otherwise, wait the
    // full realtime phases out.
    return doneMs - startMs < FAST_IMAGES_MS ? "hidden" : realtimeWaitPhase;
  });

  return {
    teethImagePlaceholderPhase,
    registerToothImage: (element: HTMLImageElement) => {
      element.addEventListener("load", loadCallback);
      total += 1;
      if (total === 1) {
        onMount(() => {
          setLoading((old) => ({ ...old, startMs: Date.now() }));
          const timeoutMiddle = setTimeout(() => {
            setLoading((old) => ({ ...old, realtimeWaitPhase: "visible" }));
          }, FAST_IMAGES_MS);
          const timeoutLate = setTimeout(() => {
            setLoading((old) => ({ ...old, realtimeWaitPhase: "hidden" }));
          }, MINIMUM_WAIT_IMAGES_MS);
          onCleanup(() => {
            clearTimeout(timeoutMiddle);
            clearTimeout(timeoutLate);
          });
        });
      }
    },
  };
}

function createTreatmentPlanContext(props: ProviderProps) {
  const renameTreatmentPlanMutation = createRenameTreatmentPlanMutation();
  return {
    renameTreatmentPlan: {
      rename(treatmentPlanId: TreatmentPlanId, name: string) {
        return renameTreatmentPlanMutation.mutateAsync({
          patientId: props.patientId,
          treatmentPlanId,
          name,
        });
      },
      isPending() {
        return renameTreatmentPlanMutation.isPending;
      },
    },
  };
}

export function OdontogramEditContextProvider(props: ProviderProps) {
  const value = {
    providers: () => props.providers,
    clinicalNoteTemplates: () => props.clinicalNoteTemplates,
    maxStagingTreatmentPlanCount: () => props.maxStagingTreatmentPlanCount,
    ...createInlineTreatmentContext(props),
    ...createTabState(props),
    ...createStickyState(),
    ...createAsyncTeeth(),
    ...createTreatmentPlanContext(props),
  } satisfies OdontogramEditContext;
  return <CONTEXT.Provider value={value}>{props.children}</CONTEXT.Provider>;
}
