import "~/observability/setup";
import { Turbo } from "@hotwired/turbo-rails";
import { start as startStorage } from "@rails/activestorage";

import "~/rich_text";
import { turboConfirm } from "~/turbo/confirm";

startStorage();

import "../../javascript/channels";
import "../../javascript/controllers";
import.meta.glob("../../javascript/src/**/*", { eager: true });

Turbo.config.forms.confirm = turboConfirm;
