export function canvasToPNGBlob(canvas: HTMLCanvasElement): Promise<Blob> {
  return new Promise<Blob>((resolve, reject) => {
    canvas.toBlob((blob) => {
      if (blob) {
        return resolve(blob);
      }
      reject(new Error("Unable to get image blob"));
    }, "image/png");
  });
}
